import './App.css';
import Header from './Header';
import Footer from './Footer';
import Body from './Body'

function App() {
  return (
    <div className="App">
      <Header />
     <Body />
     <Footer />
    </div>
  );
}

export default App;
