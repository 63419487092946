import React from 'react'

const Home = (props) => {
  return (
    <div className='home'>
      <div className='section hero'>
        <div className="container">

          <div className="hero-content">

            <h1 className="section-subtitle">Welcome To Go World Edcuations</h1>
            <h1 className="h1 hero-title">Overseas Consultancy and Education</h1>
    <p className="hero-text">
            Since our founding, our primary goal has been to provide immigration in all over country and universities. Our impact is speak louder than our word.
            </p>
            <h1>   Services Offered</h1>
            <p className="hero-text">
Welcome to the Immigration Services Center, the most efficient Visa Service Provider for all immigration and coaching. Checkout our service for country.        </p>
  </div>

          <figure className="hero-banner">
            <img src={require('../src/Components/student.png')} width="587" height="839" alt="hero banner" className="w-100" />
          </figure>
        </div>
      </div>
    </div>
  )
}

export default Home

