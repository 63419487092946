import React from 'react'

const Whygoworld = () => {
  return (
    <div>
      <section className='section about'>
        <div className="container">

          <figure className="why-banner">
            <img src={require("../src/Components/Image2.jpg")} width="470" height="538" loading="lazy" alt="about banner"
            
              className="w-100" />
          </figure>

          <div className="about-content">

            <p className="section-subtitle">Why Go-World</p>
            <p className="section-text section-text-1">
            Go World is a premier institution that can singlehandedly manage all your needs of overseas education, efficiently. We have realised the dreams of more than 3000 students, placing them in top universities around the globe. All you should have is a vision, and we will put all our efforts into making it a reality. Yet not convinced? Let us give you a good deal of reasons to come to Go World 
             </p>
             <p className="section-subtitle">   STEPWISE SUPERVISION</p>
             <p className="section-text section-text-1">
             Go World knows the field of overseas education inside out. We have a strong network of universities that can help the students choose the best where they fit. Our talented personnel supervise every step of the application and admission procedure. With us, there is nothing that can go wrong!
             </p>


          </div>
        </div>
      </section>
    </div>
  )
}

export default Whygoworld
