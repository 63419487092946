import React from 'react'

const Footer = () => {
  return (
    <footer className="footer">

      <div className="footer-top section">
        <div className="container">

          <div className="footer-brand">

            <a href="#" className="logo"> ABOUT US.</a>

            <p className="footer-text">
            Welcome to VisaPress Immigration Consulting Responsive Website Template

We have a global presence with a strategic network of own and associate offices in 25 locations across the world.
 We are planning to extend our services to over 20 offices by the year end.
            </p>

    
          </div>

          <ul className="footer-list">

            <li>
              <p className="footer-list-title">COUNTRY</p>
            </li>

            <li>
              <a href="#" className="footer-link">
                <span className='plus'>+</span>
                <span className="span">Canada</span>
              </a>
            </li>
            <li>
              <a href="#" className="footer-link">
                <span className='plus'>+</span>

                <span className="span">  United States</span>
              </a>
            </li>

            <li>
              <a href="#" className="footer-link">
                <span className='plus'>+</span>

                <span className="span">United Kingdom
</span>
              </a>
            </li>

            <li>
              <a href="#" className="footer-link">
                <span className='plus'>+</span>

                <span className="span">Australia</span>
              </a>
            </li>

            <li>
              <a href="#" className="footer-link">
                <span className='plus'>+</span>

                <span className="span">New Zealand</span>
              </a>
            </li>

            <li>
              <a href="#" className="footer-link">
                <span className='plus'>+</span>

                <span className="span"> Singapore</span>
              </a>
            </li>

          </ul>

          <ul className="footer-list">

            <li>
              <p className="footer-list-title">VISAS</p>
            </li>
           
            <li>
              <a href="#" className="footer-link">
                <span className='plus'>+</span>
                <span className="span">  Students Visas</span>
              </a>
            </li>

            <li>
              <a href="#" className="footer-link">
                <span className='plus'>+</span>

                <span className="span">Business Visas</span>
              </a>
            </li>

            <li>
              <a href="#" className="footer-link">
                <span className='plus'>+</span>

                <span className="span">Family Visas</span>
              </a>
            </li>

            <li>
              <a href="#" className="footer-link">
                <span className='plus'>+</span>

                <span className="span">Travel Visas</span>
              </a>
            </li>

            <li>
              <a href="#" className="footer-link">
                <span className='plus'>+</span>

                <span className="span">Visitor Visas</span>
              </a>
            </li>

            <li>
              <a href="#" className="footer-link">
                <span className='plus'>+</span>

                <span className="span">Work Visas</span>
              </a>
            </li>

          </ul>

          <ul className="footer-list">

            <li>
              <p className="footer-list-title">Contact Us</p>
            </li>

            <li className="footer-item">
              <div className="item-icon">
                <ion-icon name="location-outline"></ion-icon>
              </div>

              <address className="item-text">
              office:# 103,B-Block,,<br />
              Santosh Plaza Apartment,
Champapet Road,Balaji Nagar,
Saroor Nagar West, Saidabad,
Hyderabad-500 059.T.S.INDIA
              </address>
            </li>

            <li className="footer-item">
              <div className="item-icon">
                <ion-icon name="call-outline"></ion-icon>
              </div>

              <a href="tel:+917052101786" className="footer-link">+91-9396972184</a>,
              <a href="tel:+917052101786" className="footer-link">+91-9666677737</a>

            </li>

            <li className="footer-item">
              <div className="item-icon">
                <ion-icon name="mail-outline"></ion-icon>
              </div>

              <a href="mailto:help@example.com" className="footer-link">Info@goworld.co.in</a>,
              <a href="mailto:help@example.com" className="footer-link">www.goworld.co.in</a>

            </li>

          </ul>

        </div>
      </div>

      <div className="footer-bottom">
        <div className="container">

          <p className="copyright">
            &copy; 2023 All Rights Reserved.
          </p>


        </div>
      </div>

    </footer>
  )
}

export default Footer