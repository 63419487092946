import React, { useEffect, useState } from 'react'
import Home from './Home'
import About from './About'
import Whygoworld from './Whygoworld'
import Coaching from './Coaching'
import Apply from './Apply'
// import './Body.css'

const Body = () => {

  return (
    <div>
    <div id='home'>
      <Home  />
    </div>
   
      <div>
        <div id='whygoworld'>
          <Whygoworld />
        </div>
        <div id='about'>
          <About />
        </div>
        <div id='coaching'>
          <Coaching />
        </div>
       
        <div id='apply'>
          <Apply />
        </div>

      </div>
  </div>
)
}


export default Body