import React, { useEffect, useState } from 'react'

const Header = (props) => {

  const [toggleNav, setToggleNav] = useState(false)
  const [active, setActive] = useState('home')

  const handleClick = (link) => {
    setActive(link)
  }

  return (
    <header className="header">
      <div className={`header-bottom ${props.isHeaderActive ? ' active' : ''}`}>
        <div className="container">
          <a href="#" className="goo">
            {/* <div className='goo.png'>

            </div> */}
            <img src={require('../src/Components/goo.png')} className='goo-png' />
          </a>

          <nav className={`navbar container ${toggleNav ? "active" : ""}`} data-navbar>
            <ul className="navbar-list">

              <li>
                <a href="#home" className={`navbar-link ${active === 'home' ? 'active-item' : ''}`} onClick={() => {
                  setToggleNav(!toggleNav)
                  setActive('home')
                }}>Home</a>
              </li>


              <li>
                <a href="#about" className={`navbar-link ${active === 'about' ? 'active-item' : ''}`} onClick={() => {
                  setToggleNav(!toggleNav)
                  setActive('about')
                }}>About</a>
              </li>

              <li>
                <a href="#whygoworld" className={`navbar-link ${active === 'whygoworld' ? 'active-item' : ''}`} onClick={() => {
                  setToggleNav(!toggleNav)
                  setActive('whygoworld')
                }}>Whygoworld</a>
              </li>

              <li>
                <a href="#coaching" className={`navbar-link ${active === 'coaching' ? 'active-item' : ''}`} onClick={() => {
                  setToggleNav(!toggleNav)
                  setActive('coaching')
                }}>Coaching</a>
              </li>

            </ul>
          </nav>

          <button className="btn">Apply</button>
          

        </div>
      </div>

    </header >

  )
}

export default Header